import React from "react";
import styled from "styled-components";
import DefaultLetter from "./Letter";

const StyledLetterRender = styled.div`
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: bold;
`;

const LetterRenderer = ({ className, word, Letter = DefaultLetter }) => {
  const wordArray = word.split("");
  return (
    <StyledLetterRender className={className}>
      {wordArray.map((item, index) => (
        <Letter index={index} key={`${item}${index}`}>
          {item}
        </Letter>
      ))}
    </StyledLetterRender>
  );
};

export default LetterRenderer;
