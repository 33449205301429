import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { Heading } from "masterpiece-ui";

import Stats from "./Stats";
import Data30Days from './30Days';

export default function PageTemplate({ source }) {
  return (
    <MDXProvider
      components={{
        Stats,
        Heading,
        Data30Days
      }}
    >
      <MDXRenderer>{source}</MDXRenderer>
    </MDXProvider>
  );
}
