import React from "react";
import styled from "styled-components";
const year = new Date().getYear() + 1900;

const Copyright = styled.footer`
  padding: 10px;
  color: #aaa;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
const Bull = styled.div`
padding: 0 1em; `

const Copy = ({ className }) => (
  <Copyright className={className}>
    <div>Copyright &copy; 1999 - {year} Steve Bullis</div>
    <Bull> &bull; </Bull>
    <div>All Rights Reserved</div>
  </Copyright>
);

export default Copy;
