import React, { useState, useEffect } from "react";
import styled from "styled-components";

import jsonp from "jsonp";

const timeStatsUrl =
  "https://wakatime.com/share/@04780b04-dbbc-4c44-9975-27f4880d4e13/669a3823-4257-40d2-8cc0-2c6eebce0bec.json";

const Data30Days = () => {
  const [highest, setHighest] = useState([]);
  const [time, setTime] = useState([]);
  console.log(highest);
  useEffect(() => {
    jsonp(timeStatsUrl, null, (err, data) => {
      if (err) {
        console.error(err.message);
      } else {
        //console.log(data);
        setTime(
          data.data.reduce(
            (prev, item) => prev + item.grand_total.total_seconds,
            0
          )
        );

        setHighest(
          data.data.reduce(
            (prev, item) =>
              item.grand_total.total_seconds > prev.grand_total.total_seconds
                ? item
                : prev,
            { grand_total: { total_seconds: 0 } }
          )
        );
      }
    });
  }, []);

  const { grand_total = {}, range = {} } = highest;

  return time > 0 ? (
    <Flex>
      <Wrapper>
        <Label>Total</Label>
        <Hour>{Math.ceil(time / 60 / 60)} Hours</Hour>
      </Wrapper>

      <Wrapper>
        <Label>Longest Session</Label>
        <Hour>{grand_total.text}</Hour>
      </Wrapper>
      <Wrapper>
        <Label>Longest Day</Label>
        <Hour>{range.text}</Hour>
      </Wrapper>
    </Flex>
  ) : null;
};

export default Data30Days;
const Label =styled.h2`
    font-size: 18px;
    margin:0;
`
const Wrapper = styled.div`
    margin: 0 30px;
`
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Hour = styled.div`
  color: var(--primary-color);
  font-size: 24px;
`;
