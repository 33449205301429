import React from "react";
import styled from "styled-components";
export const Line = styled.div`
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    width: 2px;
    height: 70%;
    transform-origin: 50% 100%;
    background: var(--primary-color);
    margin: auto;
    transform: translateY(-100%);

    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemainlinetop;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
`;

const Animations = styled.div`

  .page.main .line-top.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemainlinetop;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  .page.main .line-top.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemainlinetop {
    0% {
      transform: translateY(-100%) scaleY(1);
    }
    18.181818181818183% {
      transform: translateY(0%) scaleY(0);
    }
    100% {
      transform: translateY(0%) scaleY(0);
    }
  }
  .page.main .circle1.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemaincircle1;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .page.main .circle1.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemaincircle1 {
    0% {
      border-width: 100px;
      opacity: 1;
      transform: scale(0);
    }
    18.181818181818183% {
      border-width: 100px;
      opacity: 1;
      transform: scale(0);
    }
    34.54545454545455% {
      opacity: 1;
    }
    36.36363636363637% {
      border-width: 0px;
      opacity: 0;
      transform: scale(1);
    }
    100% {
      border-width: 0px;
      opacity: 0;
      transform: scale(1);
    }
  }
  .page.main .circle2.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemaincircle2;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .page.main .circle2.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemaincircle2 {
    0% {
      border-width: 200px;
      opacity: 1;
      transform: scale(0);
    }
    29.090909090909093% {
      border-width: 200px;
      opacity: 1;
      transform: scale(0);
    }
    34.54545454545455% {
      opacity: 1;
    }
    45.45454545454545% {
      border-width: 0px;
      opacity: 0;
      transform: scale(1);
    }
    100% {
      border-width: 0px;
      opacity: 0;
      transform: scale(1);
    }
  }
  .page.main .rectangle.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemainrectangle;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .page.main .rectangle.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemainrectangle {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) rotate(30deg) translate(0px) scale(0.3);
    }
    36.36363636363637% {
      opacity: 0;
      transform: translate(-50%, -50%) rotate(30deg) translate(0px) scale(0.3);
    }
    38.18181818181819% {
      opacity: 1;
    }
    54.54545454545454% {
      opacity: 1;
    }
    63.63636363636363% {
      opacity: 0;
      transform: translate(-50%, -50%) rotate(0deg) translate(-100px) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) rotate(0deg) translate(-100px) scale(1);
    }
  }
  .page.main .star5.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemainstar5;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .page.main .star5.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemainstar5 {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(0px, 0px) rotate(0deg)
        scale(0.3);
    }
    36.36363636363637% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(0px, 0px) rotate(0deg)
        scale(0.3);
    }
    38.18181818181819% {
      opacity: 1;
    }
    54.54545454545454% {
      opacity: 1;
    }
    63.63636363636363% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(-5px, -98px) rotate(150deg)
        scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(-5px, -98px) rotate(150deg)
        scale(1);
    }
  }
  .page.main .circle3.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemaincircle3;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .page.main .circle3.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemaincircle3 {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(0px, 0px) rotate(0deg)
        scale(0.3);
    }
    36.36363636363637% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(0px, 0px) rotate(0deg)
        scale(0.3);
    }
    38.18181818181819% {
      opacity: 1;
    }
    54.54545454545454% {
      opacity: 1;
    }
    63.63636363636363% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(95px, -40px) rotate(150deg)
        scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(95px, -40px) rotate(150deg)
        scale(1);
    }
  }
  .page.main .polygon6.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemainpolygon6;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .page.main .polygon6.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemainpolygon6 {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(0px, 0px) rotate(0deg)
        scale(0.3);
    }
    36.36363636363637% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(0px, 0px) rotate(0deg)
        scale(0.3);
    }
    38.18181818181819% {
      opacity: 1;
    }
    54.54545454545454% {
      opacity: 1;
    }
    63.63636363636363% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(75px, 75px) rotate(150deg)
        scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(75px, 75px) rotate(150deg)
        scale(1);
    }
  }
  .page.main .triangle.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemaintriangle;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .page.main .triangle.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemaintriangle {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(0px, 0px) rotate(0deg)
        scale(0.3);
    }
    36.36363636363637% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(0px, 0px) rotate(0deg)
        scale(0.3);
    }
    38.18181818181819% {
      opacity: 1;
    }
    54.54545454545454% {
      opacity: 1;
    }
    63.63636363636363% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(-40px, 90px) rotate(-150deg)
        scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) translate(-40px, 90px) rotate(-150deg)
        scale(1);
    }
  }
  .page.main .character.d .back1.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemaincharacterdback1;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .page.main .character.d .back1.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemaincharacterdback1 {
    0% {
      transform: scaleX(0);
    }
    50.90909090909091% {
      transform: scaleX(0);
    }
    69.0909090909091% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(1);
    }
  }
  .page.main .character.d .back2.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemaincharacterdback2;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .page.main .character.d .back2.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemaincharacterdback2 {
    0% {
      transform: scaleX(0);
    }
    54.54545454545454% {
      transform: scaleX(0);
    }
    72.72727272727273% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(1);
    }
  }
  .page.main .character.d span.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemaincharacterdspan;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .page.main .character.d span.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemaincharacterdspan {
    0% {
      transform: translate(-100%);
    }
    56.36363636363636% {
      transform: translate(-100%);
    }
    74.54545454545453% {
      transform: translate(0%);
    }
    100% {
      transform: translate(0%);
    }
  }
  .page.main .character.a span.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemaincharacteraspan;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .page.main .character.a span.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemaincharacteraspan {
    0% {
      transform: translate(-100%);
    }
    60% {
      transform: translate(-100%);
    }
    78.18181818181817% {
      transform: translate(0%);
    }
    100% {
      transform: translate(0%);
    }
  }
  .page.main .character.y span.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemaincharacteryspan;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .page.main .character.y span.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemaincharacteryspan {
    0% {
      transform: translate(-100%);
    }
    63.63636363636363% {
      transform: translate(-100%);
    }
    81.81818181818183% {
      transform: translate(0%);
    }
    100% {
      transform: translate(0%);
    }
  }
  .page.main .character.b span.startAnimation {
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-name: __SCENEJS_KEYFRAMES_pagemaincharacterbspan;
    animation-duration: 5.5s;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  .page.main .character.b span.pauseAnimation {
    animation-play-state: paused;
  }
  @keyframes __SCENEJS_KEYFRAMES_pagemaincharacterbspan {
    0% {
      transform: translate(-100%);
    }
    67.27272727272727% {
      transform: translate(-100%);
    }
    85.45454545454547% {
      transform: translate(0%);
    }
    100% {
      transform: translate(0%);
    }
  }
`;

export default Animations;
