import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import StatsIcon from "./StatsIcon";
import Copyright from './Copyright';

const MenuItem = styled(Link)`
  color: var(--background-color);
  text-decoration: none;
  font-size: 19px;
  &:hover {
    color: var(--background-color);
  }
`;

const Image = styled.img``;

const FooterWrapper = styled.footer`

`;

const StyledCopyright = styled(Copyright)`
height: 35px;
background: var(--primary-color);
color: var(--background-color);
text-align: center;

`;

const FooterWavesWrapper = styled.div`
  background-image: var(--footer-background);
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;


const Content = styled.div`
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
`;
const Flex = styled.div`
  display: flex;
  color: var(--background-color);
  flex-wrap: wrap;
  align-items: center;
`;

const Left = styled.div`
  text-align: center;
  flex-grow: 1;
  @media screen and (max-width: 1119px) {
    padding-bottom: 25px;
  }
`;

const Box = styled.div`
  background-color: var(--primary-color);
  max-width: 200px;
  margin: auto;
  padding: 15px 10px;
  border-radius: 20px;
`

const Middle = styled.div`
  display: none;

  @media screen and (min-width: 1120px) {
    display: block;
    flex-grow: 2;
  }
`;

const Right = styled.div`
  display: none;

  @media screen and (min-width: 1120px) {
    display: block;
    flex-grow: 1;
    text-align: center;
  }
`;

const StatsText = styled.small`
color: var(--background-color);
`

export default () => {
  
  return (
  <FooterWrapper>
  <FooterWavesWrapper>
    <Content>
      <Flex>
        <Left>
          <Box>
          <MenuItem to="/stats">
            <div>
              <StatsIcon name="JavaScript" size="30px" />
              <StatsIcon name="JSX" size="30px" />
            </div>
            <StatsText>Realtime Stats</StatsText>
          </MenuItem>
          </Box>
        </Left>
        <Middle />
        <Right>
            <MenuItem to="/platformerGame">
            <Image src="/images/game.png" alt="Game Platform and Platformer Character" />
          </MenuItem>

        </Right>
      </Flex>
    </Content>
  
  </FooterWavesWrapper>
  <StyledCopyright />
  </FooterWrapper>
);
  }