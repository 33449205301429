import React from "react";
import styled from "styled-components";

import file_type_js from "../../static/images/file_types/file_type_js.svg";
import file_type_reactjs from "../../static/images/file_types/file_type_reactjs.svg";
import file_type_json from "../../static/images/file_types/file_type_json.svg";
import file_type_markdown from "../../static/images/file_types/file_type_markdown.svg";
import file_type_css from "../../static/images/file_types/file_type_css.svg";
import file_type_yaml from "../../static/images/file_types/file_type_yaml.svg";
import file_type_html from "../../static/images/file_types/file_type_html.svg";
import file_type_config from "../../static/images/file_types/file_type_config.svg";
import file_type_graphql from "../../static/images/file_types/file_type_graphql.svg";
import file_type_text from "../../static/images/file_types/file_type_text.svg";
import file_type_nginx from "../../static/images/file_types/file_type_nginx.svg";
import file_type_docker from "../../static/images/file_types/file_type_docker.svg";
import file_type_java from "../../static/images/file_types/file_type_java.svg";
import file_type_git from "../../static/images/file_types/file_type_git.svg";
import file_type_xml from "../../static/images/file_types/file_type_xml.svg";
import file_type_shell from "../../static/images/file_types/file_type_shell.svg";
import file_type_typescript from "../../static/images/file_types/file_type_typescript.svg";
import file_type_php from "../../static/images/file_types/file_type_php.svg";
import file_type_scss from "../../static/images/file_types/file_type_scss.svg";
import file_type_cf from "../../static/images/file_types/file_type_cf.svg";
import file_type_csharp from "../../static/images/file_types/file_type_csharp.svg";
import file_type_image from "../../static/images/file_types/file_type_image.svg";
import default_file from "../../static/images/file_types/default_file.svg";

const fileByName = {
  JavaScript: file_type_js,
  JSX: file_type_reactjs,
  JSON: file_type_json,
  Markdown: file_type_markdown,
  CSS: file_type_css,
  YAML: file_type_yaml,
  HTML: file_type_html,
  Properties: file_type_config,
  GraphQL: file_type_graphql,
  Text: file_type_text,
  "Nginx configuration file": file_type_nginx,
  Docker: file_type_docker,
  Java: file_type_java,
  "Git Config": file_type_git,
  XML: file_type_xml,
  Bash: file_type_shell,
  TypeScript: file_type_typescript,
  PHP: file_type_php,
  SCSS: file_type_scss,
  Git: file_type_git,
  ColdFusion: file_type_cf,
  "C#": file_type_csharp,
  "Image (png)": file_type_image,
  PowerShell: file_type_shell,
  XAML: default_file,
  Batchfile: file_type_shell,

  default: default_file,
};

const Image = styled.img`
  width: ${({size})=> size};
`;

export default ({ name, size="100px" }) => (
  <Image src={fileByName[name] || fileByName.default} size={size} />
);
