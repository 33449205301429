import React, { useEffect} from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import "../index.css";
import "typeface-open-sans";
import "typeface-roboto";
import Footer from "./Footer";
import Animations, { Line } from "./Animation";
import LetterRenderer from "../components/LetterRenderer";

import ContentBodyContainer from "./ContentBodyContainer";

import Nav from "./Nav";
import ProfileImage from './ProfileImage'
import ContactSmall from './ContactSmall';
import ReverseLetter from './ReverseLetter';
import ConfettiGenerator from 'confetti-js';

const ConfettiContainer = styled.canvas`
position: fixed;
z-index: -1;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const NavLinkContainer = styled.div`
  text-align: left;
  background: var(--primary-color);
  padding: 4px 12px;
  border-radius: 5px 20px;
  border-radius: 5px 20px;
  /* font-size: 18px; */
  margin: 20px 10px;
  display: flex;
  align-items: center;
  
`;
const NavText = styled(Link)`
  color var(--background-color);
  margin: 0 5px;
  display: block;
  text-decoration: none;
  font-family: Roboto;
  font-size: 22px;
  flex-grow: 1;
`;

const NavIcon = styled.div`
  background: url("/images/compass.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
`;

const NavLink = (props) => (
  <NavLinkContainer>
    <NavIcon />
    <NavText {...props} />
  </NavLinkContainer>
);

const RotatorContainer = styled.div`
  height: ${(props) => props.height + "px"};
  overflow: hidden;
`;

const ProfileImageContainer = styled.div`
  text-align: center;
`;



const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 15px;
  top: 0;
  @media screen and (max-width:1100px){
    background: var(--primary-color);
    margin: 0;
  }
`;

const VerticleCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: flex-start;
  flex-grow: 1;
  
  @media screen and (min-width: 1100px){
  position: fixed;
    top: 0;
    bottom: 0;
    background-color: var(--primary-color);
  }
`;

const IconContainer = styled.div`
  margin: 20px;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  padding: 0.5em;
`;

const StyledMain = styled.main`
  margin: 15px;
  flex-grow: 2;
  @media screen and (min-width: 1100px){
  padding-left: 350px;
  min-height: calc(100vh - 265px);
  }
`;

const Limit1024 = styled.div`
  max-width: 1024px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;
const Grow = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const LeftNav = styled.div`
  color: var(--foreground-color);
  font-size: 25px;
  align-items: center;
  min-width: 300px;
  text-align: left;
  @media screen and (max-width: 1120px) {
    text-align: center;
    display: none;
  }
`;

const NavVerticleCenterContainer = styled.div`
  @media screen and (min-width: 1121px) {
    max-width: 25vw;
  }
`;
const NavWrapper = styled.nav``;
const MobileBackgroundContainer = styled.div`
  @media screen and (max-width: 1120px) {
    height: 60px;
    position: fixed;
    background: var(--primary-color);
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 2px solid lightgrey;
  }

  @media screen and (min-width: 1121px) {
    display: none;
  }
`;
const MobileButtonContainer = styled.div`
  position: absolute;
  top: 5px;
  bottom: 0px;
  right: 15px;
  left: 15px;

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: space-between;
  text-align: center;
  flex-flow: row-reverse;
`;
const Button = styled.button``;

const MobileButton = () => (
  <MobileBackgroundContainer>
    <MobileButtonContainer>
      <Nav />
    </MobileButtonContainer>
  </MobileBackgroundContainer>
);

const NavMenu = () => (
  <LeftNav>
    {/* <NavLink to="/">Home</NavLink> */}
    <NavLink to="/roles/software-engineer">Software Engineer</NavLink>
    <NavLink to="/roles/business-owner">Entrepreneur</NavLink>
    <NavLink to="/roles/business-consultant">Business Consultant</NavLink>
    <NavLink to="/stats">Stats</NavLink>
    {/* <NavLink to="/stats">Projects</NavLink> */}
    {/* <NavLink to="/stats">Blog</NavLink> */}
    <NavLink as="a" href="https://resume.stevebullis.com">
      Résumé
    </NavLink>
    
  </LeftNav>
);

export default ({ title, subtitle, children }) => {
  // let colors = 'black';
  // if (typeof window !== `undefined`  && typeof document.documentElement !== `undefined`){
  //   colors = getComputedStyle(document.documentElement)
  //   .getPropertyValue('--primary-color')
  // }

  useEffect(() => {
    const confettiSettings = { target: 'confetti', props: ['circle'], clock: 5, size: 0.5, animate: true};
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
   
    return () => confetti.clear();
  }, [])
  
  return (
  <Animations>
    <ConfettiContainer id="confetti" width="100vw" height="100vh"></ConfettiContainer>
    <ContentBodyContainer>
      <Container>
        <VerticleCenterContainer>
          
          <NavVerticleCenterContainer>
            <FlexColumnContainer>
              <ProfileImageContainer>
                <Link to="/">
                  <ProfileImage
                    alt="Profile Photo inside a Ships Porthole"
                  />
                </Link>
              </ProfileImageContainer>

              <LetterRenderer word="Steve Bullis" Letter={ReverseLetter}/>
              
              <ContactSmall noText/>
              
            </FlexColumnContainer>
            <NavWrapper>
              <MobileButton />
              <NavMenu />
            </NavWrapper>
          </NavVerticleCenterContainer>
        </VerticleCenterContainer>

        <StyledMain>
          <Limit1024>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
            <Flex>
              <Grow>{children}</Grow>
            </Flex>
            {/* <Copyright /> */}
          </Limit1024>
        </StyledMain>
      </Container>
    </ContentBodyContainer>
    <Footer />
  </Animations>
);
  }