import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _ from 'lodash';

import jsonp from "jsonp";
import StatsIcon from './StatsIcon';


const languageStatsUrl =
  "https://wakatime.com/share/@04780b04-dbbc-4c44-9975-27f4880d4e13/c4d16590-a160-4a14-975f-0cbb480510fc.json";

const timeStatsUrl =
  "https://wakatime.com/share/@04780b04-dbbc-4c44-9975-27f4880d4e13/669a3823-4257-40d2-8cc0-2c6eebce0bec.json";

const Stats = () => {
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    jsonp(languageStatsUrl, null, (err, data) => {
      if (err) {
        console.error(err.message);
      } else {
        
        const otherData = _.get(data, 'data', []);
        const otherPercent = otherData.find(item => item.name === "Other");
        const total = 100 - _.get(otherPercent, 'percent', 0);

        
        setLanguages(
          data.data
            .filter(item => item.name !== "Other")
            .map(item => {
              return { ...item, value: item.percent / total };
            })
        );
      }
    });
  }, []);

  return (
    <div>
      <Flex>
        {languages.map(item => (
          <UsageItem
            key={item.name}
            name={item.name}
            value={Math.ceil(item.value * 100)}
          />
        ))}
      </Flex>
    </div>
  );
};

export default Stats;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  `



const Container = styled.div`
  min-width: 100px;
  margin: 10px;
`;

const LabelContainer = styled.div`
  text-align: center;
`;
const Header = styled.div`
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const Value = styled.div``;
const UsageItem = ({ name, value }) => {
  return (
    <Container>
      <StatsIcon name={name} />
      <LabelContainer>
        <Header>{name}</Header>
        <Value>{value > 0 ? value : "< 1"}%</Value>
      </LabelContainer>
    </Container>
  );
};
