import React from 'react'
import styled from 'styled-components';
import {LinkedinSquare} from 'styled-icons/boxicons-logos/LinkedinSquare'
import {PhoneCall} from 'styled-icons/boxicons-regular/PhoneCall'
import {Email} from 'styled-icons/material/Email'

const ContactContainer = styled.div`
max-width: 300px;
margin: auto;
`


const iconSize = `
width:  30px;
color: var(--accent-color);
margin: 10px 15px;
`

const StyledLinkedInSquare = styled(LinkedinSquare)(iconSize)

const StyledEmail = styled(Email)(iconSize)

const StyledPhoneCall = styled(PhoneCall)(iconSize)

const ContactBox = styled.div`
text-align: center;

${iconSize}
 opacity: 0.75;
 a{
   color: inherit;
 }
 a:visited {
  color: inherit;
  }
  a:active {
    color: inherit;
  }
`

const Container =styled.div`
display: inline-flex;
flex-wrap: wrap;
justify-content: space-around;
max-width: 960px;
width: 100%;
`

const Contact = ({noText})=><ContactContainer>
  <Container>
<ContactBox>
<a href='https://www.linkedin.com/in/stephen-bullis/'>

  <StyledLinkedInSquare/>
  {!noText ? <div>
  LinkedIn
  </div> : null}
  </a>
  </ContactBox>
  <ContactBox>
<a href='tel:+15207626258'>

  <StyledPhoneCall/>

  {!noText ? <div>
  Phone
  </div>: null}
  </a>
  </ContactBox>
  <ContactBox>
<a href='mailto:steve@stevebullis.com'>

  <StyledEmail/>

  {!noText ? <div>
  Email
  </div>: null}
  </a>
  </ContactBox>
  </Container>
  </ContactContainer>

export default Contact