import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const ProfileImageContainer = styled.div`
  text-align: center;
  
`;

const StyledImage = styled.img``;

const ProfileImage = ({ className }) => {
  const [hover, setHover] = useState(false);
  
  let src = hover ? "images/portHoleGame.png" : "/images/byday.png";
  if (typeof window !== `undefined`  && typeof document.documentElement !== `undefined`){
    src = getComputedStyle(document.documentElement)
    .getPropertyValue('--profile-image')
  }
  

  return (
    <ProfileImageContainer className={className}>
      <Link to="/">
        <StyledImage
          // onMouseEnter={() => setHover(true)}
          // onMouseLeave={() => setHover(false)}
          src={src}
          alt="Profile Photo"
        />
      </Link>
    </ProfileImageContainer>
  );
};

export default ProfileImage;
