import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import StatsIcon from "./StatsIcon";
import ProfileImage from './ProfileImage';

const MenuItem = styled(Link)`
  color: var(--background-color);
  text-decoration: none;
  font-size: 19px;
  &:hover {
    color: var(--background-color);
  }
`;

const StatsText = styled.small`
color: var(--background-color);
`



const NavListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const NavListWrapper = styled.div`
flex-grow: 1;
text-align: center;
background-color: var(--primary-color);
`

const NavList = (props)=>(
    <NavListWrapper>
        <ProfileImage />
        <NavListContainer {...props} />
    </NavListWrapper>
)


const Container = styled.div`
  @keyframes bounce {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes hoverIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  @keyframes hoverOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(110%);
    }
  }
`;

const NavToggle = styled.div`
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  
  background-color: var(--primary-color);
  padding: 0;
  text-transform: uppercase;
  color: var(--background-color);
  font-size: 0.6rem;
  border: 1px solid var(--accent-color);
  overflow: hidden;
  outline: 0;

  display: flex;
  flex-direction: column;
  width: 3rem;
  
  transition: all cubic-bezier(0.47, 0.07, 0.21, 1.4) 0.2s;

  ${({ open }) =>
    open
      ? `
      transform: translateY(0);
    `
      : ""}
`;
const ToggleOpen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
`;

const ToggleClose = styled(ToggleOpen)`
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.938%22%20height%3D%229.913%22%20viewBox%3D%220%200%209.938%209.913%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.89%204.968L0%201.08%201.104%200l3.864%203.888L8.858%200l1.08%201.08-3.89%203.888%203.89%203.865-1.08%201.08-3.89-3.866-3.864%203.866L0%208.833l3.89-3.865z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const Button = styled.button`
    background: none;
    border: none;

  ${({ open }) =>
    open
      ? `
    animation: bounce cubic-bezier(0.47, 0.07, 0.21, 1.6) 0.2s;
    `
      : ""}
`;

const MenuButton = ({ open, callback }) => (
  <header>
    <Button
      open={open}
      onClick={callback}
      href="#"
      title="navigation menu"
      aria-label="navigation menu"
      role="button"
      aria-controls="navigation"
      aria-expanded="false"
    >
      <NavToggle open={open}>
        {open ? <ToggleClose /> : <ToggleOpen>menu</ToggleOpen>}
      </NavToggle>
    </Button>
  </header>
);

const NavMenu = styled.nav`
  overflow: scroll;
  position: fixed;
  bottom: 60px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: calc(100% - 3.75rem);
  width: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  transition: all ease-out 0.2s;
  transform: translateX(-100%);
  opacity: 0.1;

  ${({ open }) =>
    open
      ? `
opacity: 1;
transform: translateX(0);
`
      : ""}
`;
const NavListItem = styled.li`
  display: flex;
  width: 100%;
  opacity: 0;
  transform: translateX(-10px);
  transition: all cubic-bezier(0.47, 0.07, 0.21, 1.4) 0.2s;

  ${({ open }) => `
    opacity: 1;
    transform: translateX(0);
    transition-delay: ${({ delay }) => `${delay || "0"}s`};
    `}
`;

const NavItem = styled.a`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.8rem;
  width: 100%;
  height: 100%;
  color: var(--background-color);
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.3);
  text-decoration: none;

  &:active {
    opacity: 1;
  }
`;

const NavLinkContainer = styled.div`
  text-align: left;
  background: var(--primary-color);
  padding: 4px 12px;
  border-radius: 5px 20px;
  border-radius: 5px 20px;
  /* font-size: 18px; */
  margin: 5px 5px;
  display: flex;
  align-items: center;
`;
const NavText = styled(Link)`
  color: var(--background-color);
  margin: 0 5px;
  display: block;
  text-decoration: none;
  font-family: Roboto;
  font-size: 22px;
  flex-grow: 1;
`;

const NavIcon = styled.div`
  background: url("/images/compass.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
`;

const NavLink = (props) => (
  <NavLinkContainer>
    <NavIcon />
    <NavText {...props} />
  </NavLinkContainer>
);


const Nav = ({ children }) => {
  const [open, setOpen] = useState(false);
  return (
    <Container className="container">
      <MenuButton open={open} callback={() => setOpen(!open)} />
      <div className="headnav">
        <NavMenu
          open={open}
          className={`nav js-nav ${open ? "is-open" : ""}`}
          role="first navigation"
        >
          <NavList open={open}>
            {/* <NavListItem open={open}>
              <NavItem href="#">
                <NavLink to="/">Home</NavLink>
              </NavItem>
            </NavListItem> */}
            <NavListItem open={open}>
              <NavItem href="#">
                <NavLink to="/roles/software-engineer">
                  Software Engineer
                </NavLink>
              </NavItem>
            </NavListItem>
            <NavListItem open={open}>
              <NavItem href="#">
              <NavLink to="/roles/business-owner">Entrepreneur</NavLink>
              </NavItem>
            </NavListItem>
            <NavListItem open={open}>
              <NavItem href="#">
              <NavLink to="/roles/business-consultant">
                Business Consultant
              </NavLink>
              </NavItem>
            </NavListItem>



            <NavListItem open={open}>
              <NavItem href="#">
              <NavLink to="/stats">Stats</NavLink>
              </NavItem>
            </NavListItem>
            <NavListItem open={open}>
              <NavItem href="#">
              <NavLink as="a" href="https://resume.stevebullis.com">
                Résumé
              </NavLink>
              </NavItem>
            </NavListItem>
          
          </NavList>
        </NavMenu>
      </div>

      <div>{children}</div>
    </Container>
  );
};
export default Nav;
